import React from "react"
import LayoutXT from "../components/LayoutXT"

export default function Vorsorge() {
  return (
    <LayoutXT>
      <h1 className="left">Vorsorgevollmacht, Betreuungsverfügung, Patientenverfügung</h1>
      <div className="rem-of-13 gray-a lheight18">
        Von unserem Leistungsspektrum ist auch die Beratung rund um eine
        Vorsorgevollmacht, Betreuungsverfügung und Patientenverfügung umfasst.
        Hierbei geht es primär darum, für den Fall, dass man wesentliche
        Angelegenheiten nicht mehr selbstständig regeln kann, Vorsorge zu
        treffen und die Sicherheit zu schaffen, dass lediglich
        Vertrauenspersonen mit der Übernahme von rechtlichen oder persönlichen
        Angelegenheit beauftragt sind.
      </div>
    </LayoutXT>
  )
}
